
import {
  IonButtons,
  IonContent,
  IonBackButton,
  IonPage,
  IonIcon,
  IonAvatar,
  IonList,
  IonItem,
  IonRow,
  IonCol,
} from '@ionic/vue'
import { ellipsisVertical, personAddSharp, pencil } from 'ionicons/icons'
import { useStore } from '@/store'
import _ from 'lodash'
export default {
  name: 'Profile',
  components: {
    IonButtons,
    IonContent,
    IonBackButton,
    IonPage,
    IonIcon,
    IonAvatar,
    IonList,
    IonItem,
    IonRow,
    IonCol,
  },
  setup() {
    const { state } = useStore()
    const address = _.get(state, 'user.addresses[0]', null)

    return {
      user: state.user,
      ellipsisVertical,
      personAddSharp,
      pencil,
      address,
    }
  },
}
