<template>
  <ion-page>
    <ion-content :fullscreen="true" style="text-align: center;">
      <div class="white-container">
        <ion-row class="ion-justify-content-between">
          <div class="orange-circle">
            <ion-icon
              :icon="pencil"
              size="large"
              color="light"
            />
          </div>
          <ion-buttons>
            <ion-back-button
              color="primary"
              default-href="/tabs/home"
              text=""
              icon="close"
            />
          </ion-buttons>
        </ion-row>
        <div class="profile">
          <ion-avatar>
            <img
              v-if="user.image"
              :src="user.image"
              alt="avatar"
              class="avatar"
            />
            <!-- <ion-icon v-else :icon="person" /> -->
            <img
              v-else
              class="avatar"
              src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
            />
            <div id="add-button" class="orange-circle">
              <ion-icon
                :icon="personAddSharp"
                size="large"
                color="light"
              />
            </div>
          </ion-avatar>
          <h2 class="username">
            {{ user.name + ' ' + user.surname }}
          </h2>
          <p v-if="address" class="city">
            {{ address.city }}
          </p>
          <div class="about-user">
            <p v-if="user.about">
              {{ user.about }}
            </p>
          </div>
        </div>

        <div class="numbers">
          <ion-row>
            <ion-col>
              <div class="number">
                <span class="value">{{ user.score }}</span>
              </div>
              <p class="title">
                {{ $t('Score') }}
              </p>
            </ion-col>

            <ion-col>
              <div class="number">
                <span class="value">{{ user.friends }}</span>
              </div>
              <p class="title">
                {{ $t('Friends') }}
              </p>
            </ion-col>

            <ion-col>
              <div class="number">
                <span class="value">{{ user.followers }}</span>
              </div>
              <p class="title">
                {{ $t('Followers') }}
              </p>
            </ion-col>
          </ion-row>
        </div>

        <div class="clubs">
          <p>Current team</p>
          <ion-list lines="none">
            <ion-item
              v-for="(club, i) in user.clubs"
              :key="i"
              lines="none"
            >
              <img
                v-if="club.image"
                slot="start"
                :src="club.image"
                alt="club avatar"
              />
              <!-- <ion-icon v-else :icon="person" /> -->
              <img
                v-else
                slot="start"
                src="https://gravatar.com/avatar/dba6bae8c566f9d4041fb9cd9ada7741?d=identicon&f=y"
              />
              <div class="club">
                <p class="title">
                  {{ club.name }}
                </p>
                <p class="city">
                  {{ club.name }}
                </p>
              </div>
            </ion-item>
          </ion-list>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import {
  IonButtons,
  IonContent,
  IonBackButton,
  IonPage,
  IonIcon,
  IonAvatar,
  IonList,
  IonItem,
  IonRow,
  IonCol,
} from '@ionic/vue'
import { ellipsisVertical, personAddSharp, pencil } from 'ionicons/icons'
import { useStore } from '@/store'
import _ from 'lodash'
export default {
  name: 'Profile',
  components: {
    IonButtons,
    IonContent,
    IonBackButton,
    IonPage,
    IonIcon,
    IonAvatar,
    IonList,
    IonItem,
    IonRow,
    IonCol,
  },
  setup() {
    const { state } = useStore()
    const address = _.get(state, 'user.addresses[0]', null)

    return {
      user: state.user,
      ellipsisVertical,
      personAddSharp,
      pencil,
      address,
    }
  },
}
</script>

<style scoped>
#add-button {
  position: absolute;
  bottom: 15px;
  right: 5px;
}

.orange-circle {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: var(--ion-color-secondary);
  width: 50px;
  height: 50px;
}

ion-back-button::part(icon) {
  font-size: 40px;
  color: var(--ion-color-light-contrast);
}

.profile {
  display: flex;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  padding-top: 1rem;
}

.avatar,
ion-avatar {
  position: relative;
  bottom: 17px;
  margin-bottom: -30px;
  max-width: 160px;
  max-height: 160px;
  width: 160px;
  height: 160px;
}

.username {
  text-transform: uppercase;
  font-size: 26px;
  font-weight: 300;
  font-family: var(--font-family-oswald);
}

.city {
  font-size: 14px;
  margin: 0;
  color: var(--ion-color-primary);
}

.about-user {
  position: relative;
  font-size: 14px;
  background-color: var(--ion-color-success-tint);
  padding: 10px;
  margin-top: 20px;
  border-radius: 30px;
  max-width: 350px;
}

@media (min-width: 320px) {
  .about-user:before {
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    bottom: 95%;
    right: 162px;
    border: 0.75rem solid transparent;
    border-top: none;
    border-bottom-color: var(--ion-color-success-tint);
  }
}

.numbers {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  padding: 20px;
}
.numbers ion-col {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.numbers .number {
  background-color: var(--ion-color-primary);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--ion-color-light);
  width: 75px;
  height: 75px;
}
.numbers .number .value {
  font-size: 26px;
  font-weight: 300;
  font-family: var(--font-family-oswald);
}
.numbers .number .title {
  font-size: 16px;
  text-transform: capitalize;
  font-weight: 300;
  color: var(--ion-color-light-contrast);
}

.clubs {
  margin-top: 20px;
}
.clubs img {
  border-radius: 50%;
}
.clubs p {
  margin-bottom: 5px;
}
.clubs .title {
  font-family: var(--font-family-oswald);
  font-size: 18px;
  text-transform: uppercase;
}
.club {
  display: flex;
  flex-direction: column;
}

ion-list {
  max-width: 350px;
  margin-left: auto;
  margin-right: auto;
  --ion-item-background: var(--ion-color-light);
}

ion-item {
  --background: var(--ion-color-light-shade);
  margin-bottom: 20px;
  border: 5px solid var(--ion-color-light-shade);
  border-radius: 296px;
}

ion-item::part(native) {
  padding-left: 0;
}
</style>
